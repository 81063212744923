// Cart.scss

@import "../../css-config/mixins.scss";

.cart-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 99;
  .opacity-layer {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .cart-content {
    // max-height: 400px; /* Đặt chiều cao tối đa của phần nội dung giỏ hàng */
    overflow-y: auto; 
    width: 100%;
    height: 100%;
    background-color: white;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    animation: slideCartWindow 0.3s ease forwards;
    @include md {
      width: 340px;
    }
    .cart-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .heading {
        flex-grow: 1;
        margin-bottom: 0;
        font-style: 20px;
        font-weight: 700;
        text-transform: uppercase;
      }
      .close-btn {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        svg {
          font-size: 18px;
        }
        .text {
          text-transform: uppercase;
          font-size: 14px;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
    .empty-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-top: 100px;
      svg {
        font-size: 120px;
        opacity: 0.1;
      }
      .return-cta {
        outline: 0;
        border: 0;
        height: 40px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-style: 13px;
        color: white;
        background: #8e2de2;
        border-bottom: 3px solid #6516aa;
      }
    }
    .cart-footer {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      
      .subtotal {
        padding: 20px 15px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .text {
          margin-bottom: 0;
          font-style: 20px;
          font-weight: bolder;
          font-size: medium;
          text-transform: uppercase;
          &.total {
            color: #8e2de2;
            font-weight: bolder;
            font-size: larger;
            font-style: 40px;
          }
        }
      }
      .button {
        padding: 20px 15px;
        .checkout-cta {
          outline: none;
          border: none;
          height: 50px;
          width: 40%;
          display: inline-block;
          float: left;
          cursor: pointer;
          font-size: 16px;
          color: white;
          background: #8e2de2;
          border-bottom: 3px solid #6516aa;
        }
        .continue-shopping-btn {
          outline: none;
          width: 60%;
          border: none;
          height: 50px;
          display: flex;
          float: right;
          cursor: pointer;
          font-size: 16px;
          color: rgb(26, 24, 24);
          background: #afaaaa; /* Đổi màu nền cho nút Continue Shopping */
          border-bottom: 3px solid #6516aa; /* Đổi màu viền cho nút Continue Shopping */
          box-shadow: none;
          overflow: visible;
          }
          .continue-shopping-btn:hover {
            background-color: #4CAF50; /* Green */
            color: white;
          }
        }
      }
    }
  }


@keyframes slideCartWindow {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
