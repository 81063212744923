/* CSS để sản phẩm nằm thẳng cột với nhau sử dụng Flexbox và thêm trang trí */
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-item {
  flex: 0 0 calc(33.33% - 20px);
  border: 1px solid #ddd;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s;
  justify-content: center;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}


.product-image {
  text-align: center;
  align-items: center;
  justify-content: center; /* Để hình ảnh nằm chính giữa ô */
  height: 100%; /* Để hình ảnh chiếm toàn bộ chiều cao của ô */
}

.img {
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
  display: block; /* Để hình ảnh không bị tạo khoảng trống dưới */
  align-items: center;
  justify-content: center;
}


.product-details {
  text-align: center;
}

.product-title {
  margin: 10px 0;
  white-space: nowrap; /* Tránh tiêu đề xuống dòng */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Đảm bảo không tràn qua phần khác */
}

.product-title a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.product-title a:hover {
  color: #007bff;
}

/* Thêm viền và trang trí */
.product-item::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px solid #f33030;
  z-index: -1;
  transition: opacity 0.2s;
  opacity: 0; /* Ẩn trang trí ban đầu */
}

.product-item:hover::before {
  opacity: 1; /* Hiển thị trang trí khi hover */
}
/* Trong Products.scss */
.product-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.product-rating {
  color: #ffca28; /* Màu vàng */
}

.product-favorite {
  color: #ef5459; /* Màu đỏ */
}

.star {
  font-size: 16px;
  margin-right: 3px;
}

.heart {
  font-size: 18px;
  margin-left: 3px;
}
