.additional-information {
    .info-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;
      border-radius: 8px; /* Góc bo tròn */
      overflow: hidden; /* Ẩn bớt viền đầu dòng */
    }
  
    .info-table th,
    .info-table td {
      padding: 8px;
      text-align: center;
    }
  
    .info-table th {
      background-color: #ffcc00; /* Màu nền cho hàng đầu tiên */
      color: #333; /* Màu chữ cho hàng đầu tiên */
    }
  
    .info-table th:first-child {
      border-top-left-radius: 8px;
    }
  
    .info-table th:last-child {
      border-top-right-radius: 8px;
    }
  
    .info-table td {
      border: 1px solid #ddd;
    }
  
    .info-table td:first-child {
      background-color: #e6f7ff; /* Màu nền cho cột đầu tiên */
    }
  
    .info-table td:last-child {
      background-color: #ffebcc; /* Màu nền cho cột cuối cùng */
    }
  
    .info-table td span {
      display: block;
      margin-bottom: 3px;
    }
  }
  