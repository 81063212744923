/* Search.scss */

.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-field {
  position: absolute;
  top: 30px; /* Adjust the distance from the top */
  left: 50%;
  transform: translateX(-50%);
  width: 80%; /* Set the width to 80% of the viewport */
  max-width: 600px; /* Add a maximum width to the search bar */
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-field input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
}

.form-field svg {
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
}

/* Add styles for the search results container */
.search-result-content {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 600px; /* Add a maximum width to the search results container */
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Add styles for each search result item */
.search-result-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.img-container img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}

.product-details .name {
  font-weight: bold;
}

.product-details .desc {
  color: #888;
}
