@import "../../css-config/mixins.scss";

// Category.scss

.category-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}


.category-title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 160px;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  padding-bottom: 13px;
  list-style: none;
}
.category-title:before {
  background-color: #c50000;
  content: "";
  display: block;
  height: 3px;
  width: 75px;
  margin-bottom: 5px;
}
.category-title:after {
  background-color: #c50000;
  content: "";
  display: block;
  position: absolute;
  margin-top: 10px;
  height: 3px;
  width: 200px;
  
  margin-bottom: 0.25em;
}
/* Category.scss */

/* Phần danh sách sản phẩm */
.product-list {
  display: flex;
  flex-wrap: wrap; /* Cho phép sản phẩm tự động xuống hàng khi không đủ chỗ */
  gap: 20px;
  align-items: center;
}

.product-loop-item {

  justify-content: center;
  padding: 15px;
  text-align: center;
  align-items: center;
  flex: 0 0 calc(33.33% - 20px);
  border: 1px solid #ddd;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s;
  justify-content: center;
}

.product-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  align-items: center;
}

.product-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.product-price {
  font-size: 16px;
  color: #db1111;
}
/* ... */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
