
.hero-banner {
    position: relative;
    background-color: white;
    padding: 40px 0;
    color: white;
    text-align: center;
  
    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      opacity: 0.3;
    }
  
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 20px;
  
      h1 {
        font-size: 64px;
        font-weight: bold;
        margin-bottom: 20px;
      }
  
      .ctas {
        display: flex;
        justify-content: center;
  
        .banner-cta {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
          padding: 12px 30px;
          margin: 0 10px;
          border: 2px solid white;
          cursor: pointer;
          transition: opacity 0.3s;
  
          &.version2 {
            background-color: white;
            color: #8e2de2;
          }
  
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  
    @media (min-width: 768px) {
      .background-image {
        opacity: 0.6;
      }
  
      .content {
        padding: 80px 20px;
  
        h1 {
          font-size: 80px;
        }
  
        .ctas {
          .banner-cta {
            font-size: 16px;
            padding: 14px 36px;
            margin: 0 15px;
          }
        }
      }
    }
  
    @media (min-width: 992px) {
      .background-image {
        opacity: 0.8;
      }
  
      .content {
        padding: 120px 20px;
  
        h1 {
          font-size: 112px;
        }
  
        .ctas {
          .banner-cta {
            font-size: 18px;
            padding: 16px 40px;
            margin: 0 20px;
          }
        }
      }
    }
  }
  