/* ProductDetail.css */

.product-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  //background: linear-gradient(-45deg, #eee9e7, #ece4e4, rgb(223, 230, 233), #ebeee0);

  animation: gradient 15s ease infinite;
  width: 100%;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.product-detail-image{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  -webkit-text-size-adjust: 100%;
  color: #333e48;
  font: normal normal 14px 'inter', sans-serif;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0px;
  -webkit-font-smoothing: subpixel-antialiased;
  box-sizing: border-box !important;
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
  
}
.product-detail-container h1,h2 {
  margin: 30px;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}
/* .product-detail-container h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #b80000;
} */
.product-detail-container h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -50px;
  background-color: #b80000;
}
.image {
  width: 400px;
  height: 100%;
  margin: 30px;
}
.product-detail-price{
  font-weight: bolder;
  font-size: 20px;
  text-align: center;
}

.product-detail-content {
  max-width: 800px;
  padding: 20px;
}

.product-detail-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.product-detail-content p {
  margin-bottom: 10px;
  line-height: 1.6;
}

.product-detail-features ul,
.product-detail-package-contents ul {
  list-style-type: disc;
  padding-left: 20px;
}

.product-detail-features li,
.product-detail-package-contents li {
  margin-bottom: 5px;
}

.product-detail-link a {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.product-detail-link a:hover {
  background-color: #0056b3;
}

.product-detail-features h2,
.product-detail-package-contents h2 {
  font-size: 18px;
  margin-bottom: 10px;
}
.product-detail-additional-info {
  margin-top: 20px;
}

.product-attributes-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.product-attributes-table th {
  padding: 8px;
  border: 1px solid #0a0303;
  text-align: left;
  background-color: #ccc;
}

.product-attributes-table td {
  padding: 8px;
  border: 1px solid #0a0303;
  text-align: left;
}

.product-attributes-table th {
  width: 30%;
  font-weight: bold;
}
