@import "../../css-config/mixins.scss";

.main-content {
  max-width: calc(100% - 20px);
  margin: 0 auto;
  @include md {
    max-width: 1200px;
  }
}
.cf-title-01 h2 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
  font-weight: bolder;
  margin: 40px;
}
/* .cf-title-01 h2:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #b80000;
} */
.cf-title-01 h2:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -50px;
  background-color: #b80000;
}
