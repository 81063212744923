/* Menu styles */
.main-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center; /* Center the menu horizontally */
  
}

.main-nav li {
  position: relative;
  flex: 1;
  //text-align: center; /* Align menu items in the center */
  list-style: none;
  text-transform: uppercase;
  font-weight: bolder;
  
}

.main-nav li a {
  color: #333;
  text-decoration: none;
  padding: 10px;
  display: block;
  
}
/* Menu.scss */


/* Chỉnh sửa nút Home */
.main-nav li:first-child .nav-link {
  display: initial;
  align-items: center;
  gap: 5px;
}

.main-nav li:first-child .nav-link i {
  font-size: 16px;
  
}

.main-nav li:first-child .nav-link span {
  font-size: 14px;
  //margin-left: 5px;
  
}

/* Dropdown menu styles */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
 background-color: rgb(172, 161, 161);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
  text-align: center; /* Align the dropdown menu items in the center */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
/* Thêm viền cho các phần tử con của dropdown menu */
.dropdown-menu li {
  border-bottom: 1px solid #ddd; /* Viền dưới cho mỗi liên kết */
  padding: 5px 0;
  font-size: 14px;
  display: block; /* Đảm bảo liên kết chiếm toàn bộ chiều rộng của phần tử cha */
}

/* Loại bỏ viền dưới cho phần tử cuối cùng */
.dropdown-menu li:last-child {
  border-bottom: none;
}

.nav-item:hover .dropdown-menu {
  display: block;
  opacity: 1;
  
}

.dropdown-menu li a {
  color:black;
  text-decoration: none;
  
}

/* Additional styling */
.header {
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}




/* Center the dropdown menu */
.dropdown-menu {
  left: 50%;
  transform: translateX(-50%);
}
