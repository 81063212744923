/* Tệp CheckOut.scss */
/* src/index.css */


.checkout {
  font-family: 'inter', sans-serif;
  color: #333e48;
}

.checkout-container {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.checkout-section {
  flex: 1;
  margin-right: 20px;
}

.checkout-info {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
}
.progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px 0;
  font-size: 14px;
}

.stage {
  flex: 1;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: color 0.3s;
}

.stage:not(:last-child)::after {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 2px;
  background-color: #ccc;
}

.stage.active {
  color: rgb(15, 14, 14);
}

.stage.active .icon {
  display: block;
}

.icon-check {
  display: none;
  position:absolute;
  top: -1px;
  left: 25%;
  transform: translateX(-50%);
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: white;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}


.checkout-logo img {
  width: 200px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  
}
.checkbox-label {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
}



.checkbox-text a {
  font-weight: bold;
  color: #1e87f0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.checkbox-text a:hover {
  color: #0c5da5;
}

.checkbox-text {
  display:inline-block;
  font-size: 14px;
  line-height: 1.5;
}

input[type="checkbox"]:checked + .checkbox-custom {
  background-color: #1e87f0;
  border-color: #1e87f0;
}

input[type="checkbox"]:checked + .checkbox-custom::after {
  opacity: 1;
}

button {
  padding: 10px 20px;
  background-color: var(--swiper-theme-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0052cc;
}

.checkout-cart {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
}
