/* Newsletter Section */
.newsletter-section {
  width: 100%;
  height: 900px;
  display: flex;
  align-items: center;
  /* background-image: url("../../../assets/newsletter-bg.jpeg"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.newsletter-content {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.small-text {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
}

.big-text {
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 30px;
}

/* Responsive: Medium */
@media (min-width: 768px) {
  .big-text {
    font-size: 28px;
  }
}

.form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.form input {
  width: 200px;
  height: 40px;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 12px;
  font-size: 16px;
  outline: none;
}

/* Responsive: Medium */
@media (min-width: 768px) {
  .form input {
    width: 300px;
  }
}

.form button {
  outline: none;
  border: 0;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #8e2de2;
  border-bottom: 3px solid #8e2de2;
}

/* Responsive: Medium */
@media (min-width: 768px) {
  .form button {
    width: 120px;
  }
}

.text {
  margin-bottom: 30px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.social-icons {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

.social-icons .icon {
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  height: 400px;
  margin: 20px;
}

/* Adjust the width and spacing for each col-xl-4 element */
.col-xl-4 {
  box-sizing: border-box;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  line-height: 35px;
}

/* Để căn giữa các logo */
.col-xl-4 .product-single-about-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px; /* Điều chỉnh chiều cao phù hợp */
}

/* Custom list-style for li elements */
.col-xl-4 ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.col-xl-4 ul li {
  display: flex;
  align-items: center;
  line-height: 28px;
  margin-bottom: 10px; /* Add spacing between list items */
}

.col-xl-4 ul li::before {
  content: "";
  width: 15px;
  height: 12px;
  margin-right: 10px; /* Add spacing between icon and text */
  background: url("https://www.headset-store.co.uk/templates/rm-headsetstore-2021/img/ui/icon-tick-blue@2x.png")
    center center no-repeat;
  background-size: 15px 12px;
}

/* Optional: add responsive styling for smaller screens */
@media (max-width: 768px) {
  .col-xl-4 {
    flex: 0 0 50%; /* Each col-xl-4 takes half of the container width on smaller screens */
    max-width: 50%; /* Each col-xl-4 takes half of the container width on smaller screens */
  }
}
