@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Cabin", sans-serif;
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
}
