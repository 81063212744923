/* Footer.css */

@import "../../css-config/mixins.scss";

.site-footer-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  padding: 30px;
  color: #333; /* Added font color */
  font-family: Arial, sans-serif; /* Added font family */
}

.site-footer-logo img {
  display: block;
  max-width: 100%;
  margin-bottom: 20px;
}

.site-footer-contact p {
  margin: 10px 0; /* Adjusted margin for paragraphs */
}

.site-footer-contact a {
  color: #007bff;
  text-decoration: none;
}

.site-footer-contact a:hover {
  text-decoration: underline;
}

/* Added a divider line */
.site-footer-contact::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
}

/* Center the contact details */
.site-footer-contact p,
.site-footer-contact a {
  text-align: center;
}

/* Align the logo to the center */
.site-footer-logo {
  display: flex;
  justify-content: center;
}

/* Added some spacing between the logo and contact details */
.site-footer-logo + p {
  margin-top: 10px;
}
